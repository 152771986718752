import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { parseCookies } from "nookies";
import App from "next/app";
import Head from "next/head";
import { ThemeProvider } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import ErrorPage from "next/error";
import theme from "../theme";
import { apiUrl, isBetweenTime } from "../utils/utils";

function MyApp(props) {
  const { Component, pageProps, router } = props;
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    const cookies = parseCookies();
    // get current user
    if (cookies.accessToken) {
      fetch(`${apiUrl}/v1/user/info`, {
        cache: "no-store",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.accessToken}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setCurrentUser(data.data);
        });
    }
  }, []);

  if (pageProps.statusCode) {
    return (
      <ErrorPage title={pageProps.message} statusCode={pageProps.statusCode} />
    );
  }

  // if (
  //   !currentUser &&
  //   router.pathname === "/" &&
  //   isBetweenTime("08:00:00", "18:00:00")
  // ) {
  //   return (
  //     <React.Fragment>
  //       <ThemeProvider theme={theme}>
  //         <CssBaseline />
  //         <Container maxWidth="md" disableGutters>
  //           <div
  //             style={{
  //               position: "relative",
  //               display: "flex",
  //               flexDirection: "column",
  //               alignItems: "center",
  //               justifyContent: "center",
  //               height: "100vh",
  //             }}
  //           >
  //             <div style={{ border: "2px outset #fff", padding: "10px 20px" }}>
  //               网站维护中，请稍后访问！
  //             </div>
  //           </div>
  //         </Container>
  //       </ThemeProvider>
  //     </React.Fragment>
  //   );
  // }

  return (
    <React.Fragment>
      <Head>
        <title>番茄ASMR</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta
          name="keywords"
          content="番茄助眠,asmr助眠,asmr哄睡,asmr视频,asmr福利,口腔音助眠"
        />
        <meta
          name="description"
          content="番茄ASMR提供免费海量高品质的ASMR视频音频资源，汇集国内,韩国,日本,欧美等地域ASMR主播，为你提供同人音声,耳边悄悄话,角色扮演,掏耳朵等ASMR助眠享受。"
        />
      </Head>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="md" disableGutters>
          <Component {...pageProps} currentUser={currentUser} />
        </Container>
      </ThemeProvider>
    </React.Fragment>
  );
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
};

MyApp.getInitialProps = async (appContext) => {
  const { Component, ctx } = appContext;

  // let user = { data: null };
  // let pageProps = {};
  const appProps = await App.getInitialProps(appContext);

  // const cookies = nookies.get(ctx);
  // const headers = {};
  // if (cookies.accessToken) {
  //   headers.Authorization = `Bearer ${cookies.accessToken}`;
  //   // cookie: ctx.req ? ctx.req.headers.cookie : null,
  //   const fetchUser = await fetch(`${apiUrl}/v1/user/info`, {
  //     credentials: "include",
  //     headers: headers,
  //   });
  //
  //   user = await fetchUser.json();
  // }

  // if (Component.getInitialProps) {
  //   pageProps = await Component.getInitialProps(ctx);
  // }
  return { ...appProps };
};

export default MyApp;
