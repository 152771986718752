import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { parseCookies } from "nookies";

dayjs.extend(isBetween);

export const prod = process.env.NODE_ENV === "production";

export const appName = "番茄ASMR";

// export const apiUrl = prod
//   ? "https://www.qqasmr.com/api"
//   : "http://localhost:8080/api";
//
// export const getApiUrl = req => {
//   if (prod) {
//     if (typeof window === "undefined" && req) {
//       // return `${req.protocol}://${req.headers.host}/api`;
//       // 以上代码会产生http://61.164.110.37:8081/api/userinfo 导致报错故写死
//       return "https://www.qqasmr.com/api";
//     } else {
//       return `${window.location.origin}/api`;
//     }
//   } else {
//     return "http://localhost:8080/api";
//   }
// };

export const apiUrl = "https://www.asmr.red/api";
// export const apiUrl = "http://api.qq.test";

export const getApiUrl = (req) => {
  if (typeof window === "undefined" && req) {
    // return `${req.protocol}://${req.headers.host}/api`;
    // 以上代码会产生http://61.164.110.37:8081/api/userinfo 导致报错故写死
    return "https://www.asmr.red/api";
  } else {
    return `${window.location.origin}/api`;
  }
};

// http://127.0.0.1:8080/api

export function request(url, options = {}) {
  const { method, headers, body } = options;
  const cookies = parseCookies();

  const defaultOptions = {
    credentials: "include",
    mode: "cors",
  };

  options.headers = {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
    Authorization: cookies.accessToken ? `Bearer ${cookies.accessToken}` : null,
    ...headers, //合并配置项
  };
  // Get的请求处理
  !method ? (options.method = "GET") : null;

  if (body) {
    options.body = JSON.stringify(body);
  }

  const newOptions = { ...defaultOptions, ...options };

  return fetch(apiUrl + url, newOptions)
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function formatErrorMessage(data) {
  if (data.errors) {
    return data.errors.map((i, index) => <div key={index}>{i.msg}</div>);
  } else if (data.message) {
    return data.message;
  } else {
    return "未知错误";
  }
}

// 格式化时间戳
export function formatTimestamp(timestamp, format) {
  if (timestamp) {
    return dayjs.unix(timestamp).format(format || 'YYYY-MM-DD HH:mm ');
  }
  return dayjs().format(format || 'YYYY-MM-DD HH:mm');
}

export function isBetweenTime(before, after) {
  const now = dayjs();
  const today = now.format("YYYY-MM-DD");
  const beforeTime = dayjs(`${today} ${before}`);
  const afterTime = dayjs(`${today} ${after}`);

  return now.isBetween(beforeTime, afterTime);
}

export function randomInsert(arr1, arr2) {
  arr1.forEach((value) => arr2.splice(Math.random() * arr2.length, 0, value));
  return arr2;
}

/**
 * Returns an array with arrays of the given size.
 *
 * @param myArray {Array} Array to split
 * @param chunkSize {Integer} Size of every group
 */
export function chunkArray(myArray, chunk_size) {
  let results = [];

  while (myArray.length) {
    results.push(myArray.splice(0, chunk_size));
  }

  return results;
}

/**
 * 获取随机偶数
 * @return {number}
 * @param range
 */
export function getRandomEven(range) {
  return Math.floor((Math.random() * range) / 2) * 2;
}

/**
 * 检测空对象
 * @return boolean
 @param obj
 */
export function isEmpty(obj) {
  if (!obj) {
    return true;
  }
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

/**
 * 是否是有效数字
 */
export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

/**
 * 是否在会员有效期
 */
export function isVip(vipDate) {
  if (vipDate && dayjs(vipDate).isValid()) {
    return dayjs(vipDate).isAfter(dayjs());
  }
  return false;
}

export const goodsList = [
  {
    id: 587575032289,
    name: "[领券]新一代无线蓝牙耳机双耳高清立体声适用苹果安卓",
    url: "https://s.click.taobao.com/FahAOxv",
    cover: "/static/images/goods1.jpg",
    price: "29.90",
    duration: "广告",
  },
  {
    id: 590200846209,
    name: "[领券]ASMR全硅胶睡眠耳机，可以戴着睡觉不压耳",
    url: "https://s.click.taobao.com/EfoAOxv",
    cover: "/static/images/goods2.jpg",
    price: "38:00",
    duration: "广告",
  },
];
