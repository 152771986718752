import { createTheme } from "@material-ui/core/styles";
import { red, amber } from "@material-ui/core/colors";

// Create a theme instance.
const theme = createTheme({
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: "#282828"
      }
    }
  },
  palette: {
    type: "dark",
    primary: {
      light: "#757ce8",
      main: "#ff3b2b",
      contrastText: "#fff"
    },
    secondary: {
      main: "hsla(0, 0%, 93%, 0.6)"
    },
    error: {
      main: red.A400,
      light: amber
    },
    background: {
      default: "#282828"
    },
    text: {
      secondary: "rgba(255, 255, 255, 0.7)"
    }
  }
});

export default theme;
